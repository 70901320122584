import React, { useEffect, useState, useRef } from "react";
import { SellerNav } from "./DealerNav";
import { useParams } from "react-router-dom"; // import useParams
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import api from "../api";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);
export const AlertListingCarDetails = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const dealerId = sessionStorage.getItem("user_id");
  console.log("dealer", dealerId);
  const [responseMessage, setResponseMessage] = useState("");
  const [sellerid, setSeller] = useState("");
  const [vechicleid, setVehicleId] = useState("");
  const [highestbid, setHighestBid] = useState("");
  const [location, setLocation] = useState("");
  const [vin, setVin] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [mileage, setMileage] = useState("");
  const numericMileage = Number(mileage);
  const [color, setColor] = useState("");
  const [keys, setkeys] = useState("");
  const [settire, setSetTire] = useState("");
  const [windowtint, setWindowTint] = useState("");
  const [aftermarketexhaust, setAftermarketExhaust] = useState("");
  const [aftermarkrims, setAftermarkRims] = useState("");
  const [roofrack, setRoofRack] = useState("");
  const [remotestarter, setRemoteStarter] = useState("");
  const [aftermarkstereo, setAftermarkStereo] = useState("");
  const [aftermarketspoiler, setAftermarketSpoiler] = useState("");
  const [minordamage, setMinorDamage] = useState("");
  const [interiorimage, setInteriorImage] = useState("");
  const [interiorimagetwo, setInteriorImageTwo] = useState("");
  const [interiorimagethree, setInteriorImageThree] = useState("");
  const [interiorimagefour, setInteriorImageFour] = useState("");
  const [exteriorimage, setExteriorImage] = useState("");
  const [exteriorimagetwo, setExteriorImageTwo] = useState("");
  const [exteriorimagethree, setExteriorImageThree] = useState("");
  const [exteriorimagefour, setExteriorImageFour] = useState("");
  const [dashboardimage, setDashboardImage] = useState("");
  const [dashboardimagetwo, setDashboardImageTwo] = useState("");
  const [dashboardimagethree, setDashboardImageThree] = useState("");
  const [dashboardimagefour, setDashboardImageFour] = useState("");
  const [rimsimage, setRimsImage] = useState("");
  const [rimsimagetwo, setRimsImageTwo] = useState("");
  const [rimsimagethree, setRimsImageThree] = useState("");
  const [rimsimagefour, setRimsImageFour] = useState("");
  const [fadingpaints, setFadingPaints] = useState("");
  const [rust, setRust] = useState("");
  const [haildamage, setHailDamage] = useState("");
  const [extmintcondition, setExtMintCondition] = useState("");
  const [dents, setDents] = useState("");
  const [ripsOrTears, setRipsOrTears] = useState(false);
  const [visibleStain, setVisibleStain] = useState(false);
  const [strongSmell, setStrongSmell] = useState(false);
  const [damagedSystems, setDamagedSystems] = useState(false);
  const [IntmintCondition, setIntMintCondition] = useState(false);
  const [smokeInVehicle, setSmokeInVehicle] = useState(false);
  const [hasOriginalRims, setHasOriginalRims] = useState(null);
  const [tireReplacement, setTireReplacement] = useState(null);
  const [vehicleDrivable, setVehicleDrivable] = useState(null);
  const [crackOnWindshield, setCrackOnWindshield] = useState(null);
  const [extendedWarranty, setExtendedWarranty] = useState(null);
  const [tradeInInterest, setTradeInInterest] = useState(null);
  const [hasWinterTires, setHasWinterTires] = useState(null);
  const [sellTiming, setSellTiming] = useState("");
  const [DonotNeedCar, setDonotNeedCar] = useState("");
  const [MechElectIssues, setMechElectIssues] = useState("");
  const [DownSize, setDownSize] = useState("");
  const [BuyAnotherCar, setBuyAnotherCar] = useState("");
  const [carCondition, setCarCondition] = useState("");
  const [accidentclaims, setAccidentClaims] = useState("");
  const [howmuchclaims, setHowMuchClaims] = useState("");
  const [carrims, setCarRims] = useState("");
  const [stockrim, setStockRim] = useState("");
  const [issuevichle, setIssueVichle] = useState("");
  const [issuevichledecs, setIssueVichleDecs] = useState("");
  const [carmodify, setCarMdify] = useState("");
  const [carmodifydecs, setCarMdifyDecs] = useState("");
  const [financed, setFinanced] = useState("");
  const [financeddesc, setFinancedDesc] = useState("");
  const [getbidprice, setGetBidPrice] = useState("");
  const [vechiclevideo, setVechicleVideo] = useState("");
  const [firmprice, setFirmPrice] = useState("");
  const [sellertransmission, setSellerTransmission] = useState("");
  const initialBidPrice = getbidprice ? `$${getbidprice}` : "";
  const [listedDate, setListedDate] = useState("");
  const [vechiclenotes, setVechicleNotes] = useState("");
  const [vehicledrivabledesc, setVehicleDrivableDes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [ownership, setOwnerShip] = useState("");
  const [drivingl, setDrivingL] = useState("");
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0 });
  const [topBids, setTopBids] = useState([]);
  const [currentBid, setCurrentBid] = useState(null);
  const [previousBid, setPreviousBid] = useState(null);
  const [shownow, setShowNow] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null); // Store agent details
  const [deliveryModeShow, setDeliveryModeShow] = useState(false);
  const [deliveryMode, setDeliveryMode] = useState("");
  const [deliveryModeMessage, SetdeliveryModeMessage] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [province, setProvince] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ type: "", src: "" });
  const [isInWatchlist, setIsInWatchlist] = useState(false);
  const [saledate, setDropDate] = useState("");
  const [live, setlive] = useState("");
  const navigate = useNavigate();
  const [startBid, setStartBid] = useState("");
  const [maxBid, setMaxBid] = useState("");
  const [formattedStartBid, setFormattedStartBid] = useState("");
  const [formattedMaxBid, setFormattedMaxBid] = useState("");
  const [AutoSuccessfullMesage, setAutoSuccessfullMesage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [message, setMessage] = useState("");
  const [evaluationreport, setevaluationreport] = useState("");
  const [startBidMessage, setStartBidMessage] = useState("");
  const [maxBidMessage, setMaxBidMessage] = useState("");
  const [typeone, setDashboardImageTypeOne] = useState("");
  const [typetwo, setDashboardImageTypeTwo] = useState("");
  const [typethree, setDashboardImageTypeThree] = useState("");
  const [typefour, setDashboardImageTypeFour] = useState("");
  const [labelone, setDashboardImageLabelOne] = useState("");
  const [labeltwo, setDashboardImageLabelTwo] = useState("");
  const [labelthree, setDashboardImageLabelThree] = useState("");
  const [labelfour, setDashboardImageLabelFour] = useState("");
  const [selectedFileCar, setSelectedFileCar] = useState(null);
  const [getPrice, setGetPrice] = useState("");
  const [averageprice, setGetaveragePrice] = useState("");
  const [belowprice, setGetbelowPrice] = useState("");
  const [CarfaxReportUrl, setCarfaxReportUrl] = useState("");
  const [setLowPrice, setLow_Price] = useState("");
  const [setAveragePrice, setAverage_Price] = useState("");
  const [setAwesomePrice, setAwesome_Price] = useState("");
  const [CarfaxReportHideLiensUrl, setCarfaxReportHideLiensUrl] = useState("");
  const [hasline, setHasLiens] = useState("");
  const [relsitNumber, setrelsitNumber] = useState("");
  console.log(relsitNumber);
  const formatNumber = (num) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const debounceTimer = useRef(null);
  const handleBidChange = (e, bidType) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    const parsedValue = parseFloat(value) || 0;

    if (bidType === "start") {
      setStartBid(value);
      setFormattedStartBid(formatNumber(value));

      // Validate while typing
      const maxValue = parseFloat(maxBid) || 0;
      if (maxValue && parsedValue >= maxValue) {
        setStartBidMessage("Start Bid must be less than Maximum Bid.");
      } else {
        setStartBidMessage(""); // Clear only if valid
      }
    } else if (bidType === "max") {
      setMaxBid(value);
      setFormattedMaxBid(formatNumber(value));

      // Validate while typing
      const startValue = parseFloat(startBid) || 0;
      if (startValue && parsedValue <= startValue) {
        setMaxBidMessage("Maximum Bid must be greater than Start Bid.");
      } else {
        setMaxBidMessage(""); // Clear only if valid
      }
    }
  };

  const handleBlur = (bidType) => {
    const startValue = parseFloat(startBid) || 0;
    const maxValue = parseFloat(maxBid) || 0;

    if (bidType === "start") {
      if (maxValue && startValue >= maxValue) {
        setStartBidMessage("Start Bid must be less than Maximum Bid.");
      }
    } else if (bidType === "max") {
      if (startValue && maxValue <= startValue) {
        setMaxBidMessage("Maximum Bid must be greater than Start Bid.");
      }
    }
  };
  useEffect(() => {
    if (startBid) {
      // Clear any existing timer
      if (debounceTimer.current) clearTimeout(debounceTimer.current);

      // Set up a new debounce timer
      debounceTimer.current = setTimeout(() => {
        const checkStartBid = async () => {
          try {
            const response = await api.get(
              `/dealer/checkminbid/${Vehicle_Id}/${dealerId}/${startBid}`
            );
            console.log("Start Bid Response:", response.data); // Debugging log
            setStartBidMessage(response.data.bidmessage || "");
          } catch (error) {
            console.error("Error checking start bid:", error);
          }
        };
        checkStartBid();
      }, 500); // Debounce delay
    } else {
      // Clear message if startBid is empty
      setStartBidMessage("");
    }

    // Cleanup timer on component unmount or dependency change
    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [startBid, Vehicle_Id, dealerId]);

  // Check Max Bid when it changes
  useEffect(() => {
    if (maxBid) {
      // Clear any existing timer
      if (debounceTimer.current) clearTimeout(debounceTimer.current);

      // Set up a new debounce timer
      debounceTimer.current = setTimeout(() => {
        const checkMaxBid = async () => {
          try {
            const response = await api.get(
              `/dealer/checkmaxbid/${Vehicle_Id}/${dealerId}/${maxBid}`
            );
            console.log("Max Bid Response:", response.data); // Debugging log
            setMaxBidMessage(response.data.bidmessage || "");
          } catch (error) {
            console.error("Error checking max bid:", error);
          }
        };
        checkMaxBid();
      }, 500); // Debounce delay
    }

    // Cleanup timer on component unmount or dependency change
    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, [maxBid, Vehicle_Id, dealerId]);

  const isButtonDisabled = startBidMessage || maxBidMessage;
  const CLIENT_ID =
    "412726820929-74ofbuuh9kh52o4v7njjn3biv7oo0ue5.apps.googleusercontent.com";
  const API_KEY = "AIzaSyCRz5kOvI1pwXy1UO8B0XxDsYCPXpHZv2w";
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";
  // Function to open the modal
  const [imageIndex, setImageIndex] = useState(0); // Track the current image index

  const imageList = [
    interiorimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`,
    interiorimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`,
    interiorimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`,
    interiorimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`,
    exteriorimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`,
    exteriorimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`,
    exteriorimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`,
    exteriorimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`,
    dashboardimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`,
    dashboardimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`,
    dashboardimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`,
    dashboardimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`,
    rimsimage &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`,
    rimsimagetwo &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`,
    rimsimagethree &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`,
    rimsimagefour &&
      `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`,
  ].filter(Boolean); // This filters out any falsy values like null or undefined

  const openModal = (type, src) => {
    setModalContent({ type, src });
    setImageIndex(imageList.indexOf(src)); // Set the image index when the modal is opened
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Function to handle keyboard navigation
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        navigateImage("prev");
      } else if (e.key === "ArrowRight") {
        navigateImage("next");
      }
    };

    // Add event listener for keydown when modal is open
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    // Clean up the event listener when modal is closed
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen, imageIndex]);
  const navigateImage = (direction) => {
    if (direction === "prev") {
      setImageIndex((prevIndex) =>
        prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setImageIndex((prevIndex) =>
        prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
      );
    }
    setModalContent({ ...modalContent, src: imageList[imageIndex] });
  };
  useEffect(() => {
    fetchData();
    fetchAgentData();
    fetchShowDeliveryMode();
  }, []);
  useEffect(() => {
    if (
      Vehicle_Id &&
      (interiorimage ||
        interiorimagetwo ||
        interiorimagethree ||
        interiorimagefour ||
        exteriorimage ||
        dashboardimage ||
        dashboardimagetwo ||
        dashboardimagethree ||
        dashboardimagefour ||
        rimsimage ||
        rimsimagetwo ||
        rimsimagethree ||
        rimsimagefour)
    ) {
      initializeFlexSlider();
    }
  }, [
    Vehicle_Id,
    interiorimage,
    interiorimagetwo,
    interiorimagethree,
    interiorimagefour,
    exteriorimage,
    dashboardimage,
    rimsimage,
  ]);
 
  const fetchData = async () => {
    try {
      const response = await api.get(`/seller/vehicle/${Vehicle_Id}`);
      const vehicleData = response.data;
      console.log(vehicleData);
      // Log the entire API response
      setSeller(vehicleData.vehicle.Seller_Id);
      setListedDate(vehicleData.vehicle.Listed_Date || "");
      const listedDate = vehicleData.vehicle.Listed_Date;
      setFirmPrice(vehicleData.vehicle.Reserve_Price);
      const dbDate = vehicleData.vehicle.Live_Auction_DateTime;
      setlive(vehicleData.vehicle.Live_Auction_DateTime || "");
      const formattedDate = new Date(dbDate).toISOString();
      setDropDate(formattedDate); // Update saledate with formatted d
      setLocation(vehicleData.vehicle.Location || "");
      setevaluationreport(vehicleData.vehicle.evaluation_report || "");
      setHighestBid(vehicleData.HighestBid);
      setVin(vehicleData.vehicle.VIN || "");
      setYear(vehicleData.vehicle.Year || "");
      setMake(vehicleData.vehicle.Make || "");
      setModel(vehicleData.vehicle.Model || "");
      setTrim(vehicleData.vehicle.Trim || "");
      setMileage(vehicleData.vehicle.Mileage || "");
      setColor(vehicleData.vehicle.Color || "");
      setkeys(vehicleData.vehicle.Car_Keys || "");
      setSetTire(vehicleData.vehicle["2_Sets_Of_Tire"] || "");
      setWindowTint(vehicleData.vehicle["Win_Tint"] || "");
      setAftermarketExhaust(vehicleData.vehicle["Aftermark_Exhaust"] || "");
      setAftermarkRims(vehicleData.vehicle["Aftermark_Rims"] || "");
      setRoofRack(vehicleData.vehicle["Roof_Rack"] || "");
      setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
      setRemoteStarter(vehicleData.vehicle["Remote_Start"] || "");
      setAftermarkStereo(vehicleData.vehicle["Aftermark_Stereo"] || "");
      setAftermarketSpoiler(vehicleData.vehicle["Aftermark_Spoiler"] || "");
      setMinorDamage(vehicleData.vehicle["Minor_Damage"] || "");
      setInteriorImage(vehicleData.vehicle["Interior_Image"] || "");
      setInteriorImageTwo(vehicleData.vehicle["Interior_Image2"] || "");
      setInteriorImageThree(vehicleData.vehicle["Interior_Image3"] || "");
      setInteriorImageFour(vehicleData.vehicle["Interior_Image4"] || "");
      setExteriorImage(vehicleData.vehicle["Exterior_Image"] || "");
      setExteriorImageTwo(vehicleData.vehicle["Exterior_Image2"] || "");
      setExteriorImageThree(vehicleData.vehicle["Exterior_Image3"] || "");
      setExteriorImageFour(vehicleData.vehicle["Exterior_Image4"] || "");
      setDashboardImage(vehicleData.vehicle["Dashboard_Image"] || "");
      setDashboardImageTwo(vehicleData.vehicle["Dashboard_Image2"] || "");
      setDashboardImageThree(vehicleData.vehicle["Dashboard_Image3"] || "");
      setDashboardImageFour(vehicleData.vehicle["Dashboard_Image4"] || "");
      setRimsImage(vehicleData.vehicle["Rims_Image"] || "");
      setRimsImageTwo(vehicleData.vehicle["Rims_Image2"] || "");
      setRimsImageThree(vehicleData.vehicle["Rims_Image3"] || "");
      setRimsImageFour(vehicleData.vehicle["Rims_Image4"] || "");
      setFadingPaints(vehicleData.vehicle["Fading_Paints"] || "");
      setRust(vehicleData.vehicle["Rust"] || "");
      setHailDamage(vehicleData.vehicle["Hail_Damage"] || "");
      setExtMintCondition(vehicleData.vehicle["Ext_Mint_Condition"] || "");
      setDents(vehicleData.vehicle["Dents"] || "");
      setRipsOrTears(vehicleData.vehicle["Seats_Rips_Tears"] || "");
      setVisibleStain(vehicleData.vehicle["Seats_Visible_Stain"] || "");
      setStrongSmell(vehicleData.vehicle["Strong_Smell"] || "");
      setDamagedSystems(vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] || "");
      setIntMintCondition(vehicleData.vehicle["Int_Mint_Condition"] || "");
      setSmokeInVehicle(vehicleData.vehicle["Smoke_In_Vehicle"] || "No");
      setHasOriginalRims(vehicleData.vehicle["Original_Factory_Rims"] || "No");
      setTireReplacement(vehicleData.vehicle["Tires_Repld_12_Months"] || "No");
      setVehicleDrivable(vehicleData.vehicle["Vehicle_Drivable"] || "No");
      setCrackOnWindshield(vehicleData.vehicle["Windshield_Crack"] || "No");
      setExtendedWarranty(vehicleData.vehicle["Extended_Warranty"] || "No");
      setTradeInInterest(vehicleData.vehicle["TradeIn_Interested"] || "No");
      setHasWinterTires(vehicleData.vehicle["Winter_Tires"] || "No");
      setVehicleDrivableDes(
        vehicleData.vehicle["vehicle_drivable_not_explanation"]
      );
      setSellTiming(vehicleData.vehicle["How_Soon_Ready_Sell"]);
      setDonotNeedCar(vehicleData.vehicle["Donot_Need_Car"] || "");
      setMechElectIssues(vehicleData.vehicle["Mech_Elect_Issues"] || "");
      setDownSize(vehicleData.vehicle["Downsize"] || "");
      setBuyAnotherCar(vehicleData.vehicle["Buy_Another_Car"] || "");
      setCarCondition(vehicleData.vehicle["Car_Condition"]);
      setAccidentClaims(vehicleData.vehicle["Accident_Claims"] || "No");
      setHowMuchClaims(vehicleData.vehicle["How_Much_Claims"] || "");
      setCarRims(vehicleData.vehicle["Car_Rims"]);
      setStockRim(vehicleData.vehicle["Stock_Rims"] || "No");
      setIssueVichle(vehicleData.vehicle["Any_Vehicle_Issues"] || "No");
      setIssueVichleDecs(vehicleData.vehicle["Vehicle_Issues_desc"] || "");
      setCarMdify(vehicleData.vehicle["Car_Modification"] || "No");
      setCarMdifyDecs(vehicleData.vehicle["Modification_desc"] || "");
      setFinanced(vehicleData.vehicle["Leased_Financed"] || "No");
      setFinancedDesc(vehicleData.vehicle["Financed_By"] || "");
      setVehicleId(vehicleData.vehicle["Vehicle_Id"]);
      setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
      setVechicleVideo(vehicleData.vehicle["Vehicle_video"]);
      setVechicleNotes(vehicleData.vehicle["Car_Notes"]);
      setSellerTransmission(vehicleData.vehicle["Transmission_Type"]);
      setDashboardImageTypeOne(vehicleData.vehicle["Damage_Image1_Type"] || "");
      setDashboardImageTypeTwo(vehicleData.vehicle["Damage_Image2_Type"] || "");
      setDashboardImageTypeThree(
        vehicleData.vehicle["Damage_Image3_Type"] || ""
      );
      setDashboardImageTypeFour(
        vehicleData.vehicle["Damage_Image4_Type"] || ""
      );
      setDashboardImageLabelOne(vehicleData.vehicle["Damage_Label_1"] || "");
      setDashboardImageLabelTwo(vehicleData.vehicle["Damage_Label_2"] || "");
      setDashboardImageLabelThree(vehicleData.vehicle["Damage_Label_3"] || "");
      setDashboardImageLabelFour(vehicleData.vehicle["Damage_Label_4"] || "");
      setCarfaxReportHideLiensUrl(
        vehicleData.vehicle["Carfax_Report_HideLiens_Url"] || ""
      );
      setCarfaxReportUrl(vehicleData.vehicle.Carfax_Report_Url);
      setSelectedFileCar(vehicleData.vehicle.evaluation_report);
      setOwnerShip(vehicleData.vehicle.Ownership_Doc);
      setDrivingL(vehicleData.vehicle.Driving_Licence_Doc);
      setSelectedFeatures(vehicleData.vehicle.Additional_Car_Feature);
      setLow_Price(vehicleData.vehicle.Low_Price);
      setAverage_Price(vehicleData.vehicle.Average_Price);
      setAwesome_Price(vehicleData.vehicle.Awesome_Price);
      setVin(vehicleData.vehicle.VIN || "");
      setrelsitNumber(vehicleData.vehicle.Relist_Count || "");
      const vin = vehicleData.vehicle.VIN;
      setYear(vehicleData.vehicle.Year || "");
      const year = vehicleData.vehicle.Year;
      setModel(vehicleData.vehicle.Model || "");
      const model = vehicleData.vehicle.Model;
      setTrim(vehicleData.vehicle.Trim || "");
      const trim = vehicleData.vehicle.Trim;
      setMileage(vehicleData.vehicle.Mileage || "");
      const mileage = vehicleData.vehicle.Mileage;
      setMake(vehicleData.vehicle.Make || "");
      const make = vehicleData.vehicle.Make;
      try {
        const response = await api.get(
          `/seller/vehicleofferprice/${vin}/${mileage}/${trim}/${year}/${make}/${model}`
        );
        const offerprice = response.data.third_price;
        const average = response.data.second_price;
        const belowprice = response.data.first_price;

        // Create an array and sort it
        const prices = [offerprice, average, belowprice].sort((a, b) => a - b);
        console.log(prices);

        // Assign sorted values to respective price categories
        setGetbelowPrice(prices[0]); // Lowest price
        setGetaveragePrice(prices[1]); // Middle price
        setGetPrice(prices[2]); // Highest price
      } catch (error) {
        console.error("Error fetching offer price:", error);
      }
      if (vehicleData.vehicle["Has_Liens"] === 1) {
        setHasLiens("Has lien");
      }
      if (vehicleData.vehicle["Leased_Financed"] === 1) {
        setFinanced("Yes");
      }
      if (vehicleData.vehicle["Car_Modification"] === 1) {
        setCarMdify("Yes");
      }
      if (vehicleData.vehicle["Any_Vehicle_Issues"] === 1) {
        setIssueVichle("Yes");
      }
      if (vehicleData.vehicle["Stock_Rims"] === 1) {
        setStockRim("Yes");
      }
      if (vehicleData.vehicle["Accident_Claims"] === 1) {
        setAccidentClaims("Yes");
      }
      if (vehicleData.vehicle["Donot_Need_Car"] === 1) {
        setDonotNeedCar("Don’t Need The Car Anymore");
      }
      if (vehicleData.vehicle["Mech_Elect_Issues"] === 1) {
        setMechElectIssues(
          "Selling Because The Car Has Some Mechanical/ Electrical Issues"
        );
      }
      if (vehicleData.vehicle["Downsize"] === 1) {
        setDownSize("Selling To Downsize");
      }
      if (vehicleData.vehicle["Buy_Another_Car"] === 1) {
        setBuyAnotherCar("Selling To Buy Another Car");
      }
      if (vehicleData.vehicle["Winter_Tires"] === 1) {
        setHasWinterTires("Yes");
      }
      if (vehicleData.vehicle["TradeIn_Interested"] === 1) {
        setTradeInInterest("Yes");
      }
      if (vehicleData.vehicle["Extended_Warranty"] === 1) {
        setExtendedWarranty("Yes");
      }
      if (vehicleData.vehicle["Windshield_Crack"] === 1) {
        setCrackOnWindshield("Yes");
      }
      if (vehicleData.vehicle["Vehicle_Drivable"] === 1) {
        setVehicleDrivable("Yes");
      }
      if (vehicleData.vehicle["Tires_Repld_12_Months"] === 1) {
        setTireReplacement("Yes");
      }
      if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
        setHasOriginalRims("Yes");
      }
      if (vehicleData.vehicle["Original_Factory_Rims"] === 1) {
        setHasOriginalRims("Yes");
      }
      if (vehicleData.vehicle["Smoke_In_Vehicle"] === 1) {
        setSmokeInVehicle("Yes");
      }
      if (vehicleData.vehicle["2_Sets_Of_Tire"] === 1) {
        setSetTire("2 Sets of Tire");
      }
      if (vehicleData.vehicle["Win_Tint"] === 1) {
        setWindowTint("Window Tint");
      }
      if (vehicleData.vehicle["Aftermark_Exhaust"] === 1) {
        setAftermarketExhaust("Aftermarket Exhaust");
      }
      if (vehicleData.vehicle["Aftermark_Rims"] === 1) {
        setAftermarkRims("Aftermarket Rims");
      }
      if (vehicleData.vehicle["Roof_Rack"] === 1) {
        setRoofRack("Roof Rack");
      }
      if (vehicleData.vehicle["Remote_Start"] === 1) {
        setRemoteStarter("Remote Start");
      }
      if (vehicleData.vehicle["Aftermark_Stereo"] === 1) {
        setAftermarkStereo("Aftermarket Stereo");
      }
      if (vehicleData.vehicle["Aftermark_Spoiler"] === 1) {
        setAftermarketSpoiler("Aftermarket Spoiler");
      }
      if (vehicleData.vehicle["Minor_Damage"] === 1) {
        setMinorDamage("Minor Damage");
      }
      if (vehicleData.vehicle["Fading_Paints"] === 1) {
        setFadingPaints("Fading Paints");
      }
      if (vehicleData.vehicle["Rust"] === 1) {
        setRust("Rust");
      }
      if (vehicleData.vehicle["Hail_Damage"] === 1) {
        setHailDamage("Hail Damage");
      }
      if (vehicleData.vehicle["Ext_Mint_Condition"] === 1) {
        setExtMintCondition("Mint Condition");
      }
      if (vehicleData.vehicle["Dents"] === 1) {
        setDents("Dents");
      }
      if (vehicleData.vehicle["Int_Mint_Condition"] === 1) {
        setIntMintCondition("Mint Condition");
      }
      if (vehicleData.vehicle["Nav_Entmt_Ctrl_Dmg_Sys"] === 1) {
        setDamagedSystems("Damaged Systems");
      }
      if (vehicleData.vehicle["Strong_Smell"] === 1) {
        setStrongSmell("Strong Smell");
      }
      if (vehicleData.vehicle["Seats_Visible_Stain"] === 1) {
        setVisibleStain("Visible Stain On The Seats");
      }
      if (vehicleData.vehicle["Seats_Rips_Tears"] === 1) {
        setRipsOrTears("Rips Or Tears On The Seats");
      }
      try {
        const response = await api.get(
          `/seller/carfaxebadgingreport/${Vehicle_Id}/${vin}`
        );
        const data = response.data;

        if (data.Imageurl) {
          setImageUrl(data.Imageurl); // Set the image URL if available
          setMessage(data.Message || ""); // Set the message if available
        } else {
          setMessage("No Badge Found");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        setMessage("Error fetching image.");
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  const features = [
    settire,
    windowtint,
    aftermarketexhaust,
    roofrack,
    remotestarter,
    aftermarkstereo,
    aftermarketspoiler,
  ];
  const filteredFeatures = features.filter((feature) => feature);
  const featuresString = filteredFeatures.join(",");
  const featurestwo = [
    minordamage,
    fadingpaints,
    rust,
    haildamage,
    extmintcondition,
    dents,
  ];
  const filteredFeaturestwo = featurestwo.filter((feature) => feature);
  const featuresStringtwo = filteredFeaturestwo.join(",");
  const featuresthree = [
    ripsOrTears,
    visibleStain,
    strongSmell,
    damagedSystems,
    IntmintCondition,
  ];
  const filteredFeaturesthree = featuresthree.filter((feature) => feature);
  const featuresStringthree = filteredFeaturesthree.join(",");
  const initializeFlexSlider = () => {
    window.$(".flexslider").flexslider({
      animation: "slide",
      controlNav: "thumbnails",
      start: function (slider) {
        window.$("body").removeClass("loading");
      },
    });
  };
  const fetchAgentData = async () => {
    try {
      const response = await api.get(`/agent/agentdetails/${Vehicle_Id}`);
      console.log(response.data); // Log the response to check the data
      setAgentDetails(response.data[0]); // Set the first agent object
    } catch (error) {
      console.error("Error fetching agent details:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const fetchShowDeliveryMode = async () => {
    try {
      const response = await api.get(
        `/dealer/showdeliverymode/${Vehicle_Id}/${dealerId}`
      );
      const data = response.data;
      setDeliveryModeShow(data.result);
    } catch (error) {
      console.error("Error fetching agent details:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };


  const fetchDeliveryMode = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/dealer/getlastdeliverymode/${Vehicle_Id}/${dealerId}`
      );
      const mode = response.data.result?.Delivery_Mode;
      if (mode) {
        setDeliveryMode(mode); // Set the delivery mode from API
      }
    } catch (error) {
      console.error("Error fetching last delivery mode:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDeliveryMode();
  }, [Vehicle_Id, dealerId]);
  useEffect(() => {
    if (Vehicle_Id) {
      fetchState();
    }
  }, [Vehicle_Id]);

  const setAlert = async () => {
    try {
      // Making the API call with Vehicle_Id and dealerId
      const response = await api.post("/dealer/setalert", {
        vehicleid: Vehicle_Id,
        dealerid: dealerId,
      });

      console.log("Set Alert Response:", response.data); // Logging for debugging
      alert("Alert set successfully!"); // You can change this to a more user-friendly message or UI feedback
    } catch (error) {
      console.error("Error setting alert:", error);
      alert("Failed to set alert. Please try again."); // Error feedback
    }
  };
  const fetchState = async () => {
    try {
      const response = await api.get(`/car-location/${location}`);
      console.log("State:", response.data);

      if (response.data && response.data.province) {
        setProvince(response.data.province); // Set the province if it's present in the response
      } else {
        console.warn("Province not found in response");
      }
    } catch (error) {
      console.error("State fetch error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  // Fetch state data when the location changes and is non-empty
  useEffect(() => {
    if (location) {
      fetchState();
    }
  }, [location]);
  // Add Car to watch list
  const Watchlist = async () => {
    try {
      const response = await api.get(
        `/dealer/addtowatchlist/${Vehicle_Id}/${dealerId}`
      );
      const data = response.data;
      console.log("watchlist", data);
    } catch (error) {
      console.error("Error post watchlist:", error);
    }
  };

  // Add Car to watch list get
  const Watchlisfetcht = async () => {
    try {
      const response = await api.get(
        `/dealer/existinwatchlist/${Vehicle_Id}/${dealerId}`
      );
      const data = response.data;
      setIsInWatchlist(data.result);
    } catch (error) {
      console.error("Error post watchlist:", error);
    }
  };

  useEffect(() => {
    Watchlisfetcht(); // Call the function here
  }, []);
  useEffect(() => {
    function start() {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
        ],
        scope: SCOPES,
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  // Initialize Google API client
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        })
        .catch((error) =>
          console.error("Error initializing Google API client", error)
        );
    });
  }, []);
  const Calender = async () => {
    const authInstance = gapi.auth2.getAuthInstance();

    // Check if the user is signed in
    if (authInstance.isSignedIn.get()) {
      // If signed in, create the event
      createEvent();
    } else {
      // If not signed in, trigger sign-in
      authInstance
        .signIn()
        .then(() => {
          createEvent(); // Proceed with event creation after successful sign-in
        })
        .catch((error) => {
          console.error("Error signing in: ", error);
          alert("Failed to sign in. Please try again.");
        });
    }
  };

  const createEvent = () => {
    // Event data
    const event = {
      summary: `Car Chaser Auction`,
      description: ` ${year} ${make} ${model} ${trim} ${vin}`,
      start: {
        dateTime: saledate,
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: saledate, // End date and time
        timeZone: "America/Los_Angeles",
      },
    };

    // Insert event into Google Calendar
    gapi.client.calendar.events
      .insert({
        calendarId: "primary",
        resource: event,
      })
      .then((response) => {
        console.log("Event created: ", response);
        alert("Event added to calendar!");
        navigate(`/future-car-details/${Vehicle_Id}`); // Redirect after event is added
      })
      .catch((error) => console.error("Error creating event", error));
  };

  const postAutoBid = async () => {
    if (!startBid || !maxBid || !deliveryMode) {
      alert("All fields (Start Bid, Max Bid, Delivery Mode) are required.");
      return;
    }

    try {
      // Remove formatting (commas) by converting formatted values back to numbers
      const cleanStartBid = parseFloat(startBid.replace(/,/g, ""));
      const cleanMaxBid = parseFloat(maxBid.replace(/,/g, ""));

      const response = await api.post(`/dealer/setautobid`, {
        vehicle_id: Vehicle_Id,
        dealer_id: dealerId,
        start_bid_amount: cleanStartBid,
        maximum_bid_amount: cleanMaxBid,
        delivery_mode: deliveryMode,
      });
      setAutoSuccessfullMesage("Bid saved successfully");
      console.log("Bids saved successfully:", response.data);
      navigate("/future-cars");
    } catch (error) {
      console.error("Error saving bids:", error);
    }
  };

  useEffect(() => {
    const GetAutoBid = async () => {
      try {
        const response = await api.get(
          `/dealer/getautobid/${Vehicle_Id}/${dealerId}`
        );
        const data = response.data;
        console.log("auto bid s", response.data);
        // Assuming `data.result` is an array and we take the first object in that array
        if (data.result && data.result.length > 0) {
          const autoBidData = data.result[0];

          // Set the values from `Start_Bid_Amt` and `Max_Bid_Amt`
          setStartBid(autoBidData.Start_Bid_Amt.toString());
          setMaxBid(autoBidData.Max_Bid_Amt.toString());
          setDeliveryMode(autoBidData.Delivery_Mode);

          // Set the formatted values
          setFormattedStartBid(
            formatNumber(autoBidData.Start_Bid_Amt.toString())
          );
          setFormattedMaxBid(formatNumber(autoBidData.Max_Bid_Amt.toString()));
        }
      } catch (error) {
        console.error("Error fetching bids:", error);
      }
    };

    GetAutoBid();
  }, [Vehicle_Id, dealerId]);

  useEffect(() => {
    const GetMaxmiumBid = async () => {
      try {
        const response = await api.get(
          `/dealer/checkmaxbid/${Vehicle_Id}/${dealerId}/5000`
        );
        const data = response.data;
      } catch (error) {
        console.error("Error fetching bids:", error);
      }
    };

    GetMaxmiumBid();
  }, [Vehicle_Id, dealerId]);

  const dynamicLink =
    evaluationreport && evaluationreport !== ""
      ? `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${evaluationreport}`
      : null; // or any fallback URL you prefer

  // Logic to show alert if evaluationreport is empty
  return (
    <section class="car-details">
      <SellerNav />
      <div class="vehicle-detials-page mt-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 mb-lg-0 mb-4">
              <div
                className={
                  vechiclevideo && vechiclevideo !== "null"
                    ? "col-md-12 p-0"
                    : "col-md-12 p-0 text-center"
                }
              >
                <div class="car-list-image">
                  <div class="flexslider">
                    <ul className="slides">
                      {interiorimage && (
                        <>
                          <li
                            data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                          >
                            <div class="heading-damge">
                              <h3 className="vehicle-info-heading pt-3">
                                Interior Image
                              </h3>
                            </div>
                            <img
                              src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`}
                              alt="Interior"
                              onClick={() =>
                                openModal(
                                  "image",
                                  `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimage}`
                                )
                              }
                              style={{
                                cursor: "pointer",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            />
                          </li>
                        </>
                      )}
                      {interiorimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Interior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {interiorimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Interior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {interiorimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Interior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`}
                            alt="Interior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${interiorimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Exterior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Exterior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Exterior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {exteriorimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Exterior Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`}
                            alt="Exterior"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${exteriorimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Damage Image
                            </h3>
                            <div class="damage-labels">
                              {typeone &&
                                typeone !== "undefined" &&
                                typeone.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {typeone}
                                  </h3>
                                )}
                              {labelone &&
                                labelone !== "undefined" &&
                                labelone.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {labelone}
                                  </h3>
                                )}
                            </div>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Damage Image
                            </h3>
                            <div class="damage-labels">
                              {typetwo &&
                                typetwo !== "undefined" &&
                                typetwo.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {typetwo}
                                  </h3>
                                )}
                              {labeltwo &&
                                labeltwo !== "undefined" &&
                                labeltwo.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {labeltwo}
                                  </h3>
                                )}
                            </div>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagethree && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Damage Image
                            </h3>
                            <div class="damage-labels">
                              {typethree &&
                                typethree !== "undefined" &&
                                typethree.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {typethree}
                                  </h3>
                                )}
                              {labelthree &&
                                labelthree !== "undefined" &&
                                labelthree.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {labelthree}
                                  </h3>
                                )}
                            </div>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {dashboardimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Damage Image
                            </h3>
                            <div class="damage-labels">
                              {typefour &&
                                typefour !== "undefined" &&
                                typefour.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {typefour}
                                  </h3>
                                )}
                              {labelfour &&
                                labelfour !== "undefined" &&
                                labelfour.trim() !== "" && (
                                  <h3 className="vehicle-info-heading pt-3">
                                    {labelfour}
                                  </h3>
                                )}
                            </div>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`}
                            alt="Dashboard"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${dashboardimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimage && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Rims Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimage}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagetwo && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Rims Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagetwo}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagethree && (
                        <li
                          data-thumb={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Rims Image
                            </h3>
                          </div>
                          <img
                            src={`https://topdevit.com/clients/carchaser/public/uploads/${Vehicle_Id}/${rimsimagethree}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagethree}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                      {rimsimagefour && (
                        <li
                          data-thumb={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                        >
                          <div class="heading-damge">
                            <h3 className="vehicle-info-heading pt-3">
                              Rims Image
                            </h3>
                          </div>
                          <img
                            src={`https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`}
                            alt="Rims"
                            onClick={() =>
                              openModal(
                                "image",
                                `https://backend.carchaser.ca/uploads/${Vehicle_Id}/${rimsimagefour}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div>
                {vechiclevideo && vechiclevideo !== "null" && (
                  <div className="col-md-12 p-0">
                    <div id="vechiclevideo">
                      <h2>Vehicle Video</h2>
                      <div
                        onClick={() =>
                          openModal(
                            "video",
                            `https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <video
                          width="250"
                          height="315"
                          controls
                          preload="metadata"
                        >
                          <source
                            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
                            type="video/mp4"
                          />
                          <source
                            src={`https://backend.carchaser.ca/videos/${Vehicle_Id}/${vechiclevideo}`}
                            type="video/quicktime"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                )}
                {/* Modal Implementation */}
                {isModalOpen && (
                  <div className="modal-overlay" onClick={closeModal}>
                    <div
                      className="modal-content"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span className="close-button" onClick={closeModal}>
                        &times;
                      </span>

                      {/* Add Previous and Next Arrows */}
                      <button
                        className="prev-arrow"
                        onClick={() => navigateImage("prev")}
                      >
                        &#10094;
                      </button>

                      {modalContent.type === "video" ? (
                        <video
                          width="100%"
                          height="100%"
                          controls
                          preload="metadata"
                        >
                          <source src={modalContent.src} type="video/mp4" />
                          <source
                            src={modalContent.src}
                            type="video/quicktime"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        modalContent.src && (
                          <img
                            src={modalContent.src}
                            alt="Vehicle"
                            style={{ width: "100%", height: "auto" }}
                          />
                        )
                      )}

                      {/* Add Next Arrow */}
                      <button
                        className="next-arrow"
                        onClick={() => navigateImage("next")}
                      >
                        &#10095;
                      </button>
                    </div>
                  </div>
                )}

                {/* CSS for modal styling */}
                <style jsx>{`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    position: relative;
    width: 90%;
    max-width: 1000px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 99;
  }
  .modal-content img {
    width: auto !important;
    height: 100% !important;
    margin: auto !important;
  }

  /* Add styles for navigation arrows */
  .prev-arrow, .next-arrow {
    position: absolute;
    top: 50%;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    font-size: 30px;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .prev-arrow {
    left: 10px;
  }
  .next-arrow {
    right: 10px;
  }
`}</style>
              </div>
            </div>
            <div class="col-lg-8 mb-lg-0 mb-4">
              <div class="vehicle-details-panel">
                <h3 class="headline p-2">Vehicle Details</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="details-info-one">
                      <ul class="list">
                        <li>
                          <h4 class="info-headline">Location:</h4>
                          <span class="info">{location}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Vin Number:</h4>
                          <span class="info">{vin}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Year:</h4>
                          <span class="info">{year}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Make:</h4>
                          <span class="info">{make}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Model:</h4>
                          <span class="info">{model}</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="details-info-one">
                      <ul class="list">
                        <li>
                          <h4 class="info-headline">Trim :</h4>
                          <span class="info">{trim}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Transmission :</h4>
                          <span class="info">{sellertransmission}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Odometer :</h4>
                          <span class="info">{mileage.toLocaleString()}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Color :</h4>
                          <span class="info">{color}</span>
                        </li>
                        <li>
                          <h4 class="info-headline">Keys :</h4>
                          <span class="info">{keys}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="milaage-msg">
                  {numericMileage > 165000 && (
                    <p className="vehicle-info-heading pt-3 text-danger">
                      this car will be sold as is
                    </p>
                  )}
                </div>
                <div class="row m-0">
                  <h4 class="info-headline">External Damage to the vehicle.</h4>
                  <span class="info-value w-100 mb-3">{featuresStringtwo}</span>

                  <h4 class="info-headline">Any interior damage?</h4>
                  <span class="info w-100 mb-4">{featuresStringthree}</span>
                  <h4 class="info-headline">Additional car features</h4>
                  <span class="info w-100 mb-4">
                    <strong>{selectedFeatures}</strong>
                  </span>
                  <h4 class="info-headline">Car ReList Count</h4>
                  <span class="info w-100 mb-4">
                    <strong>{relsitNumber}</strong>
                  </span>
                  <div className="estimate-average-new pt-3">
                    <div className="row">
                      <div className="col-lg-4 col-4 fw-bold">
                        <div className="circle-grey mb-2">
                          <span class="first-disc"></span>
                        </div>
                        <p>low</p>
                        <p>
                          $
                          {setLowPrice || setLowPrice === 0
                            ? setLowPrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                      <div className="col-lg-4 col-4 fw-bold text-center">
                        <div className="circle-grey mb-2">
                          <span class="second-disc"></span>
                        </div>
                        <p>average</p>
                        <p>
                          $
                          {setAveragePrice || setAveragePrice === 0
                            ? setAveragePrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                      <div className="col-lg-4 col-4 fw-bold text-right">
                        <div className="circle-grey mb-2">
                          <span class="third-disc"></span>
                        </div>
                        <p>awesome</p>
                        <p>
                          $
                          {setAwesomePrice || setAwesomePrice === 0
                            ? setAwesomePrice.toLocaleString()
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h4 class="info-headline">
                    Our top priority is to sell your car.
                  </h4>
                  <div class="details-info-two">
                    <ul class="list">
                      <li>
                        <p>Do you smoke in this vehicle?</p>
                        <span class="value">{smokeInVehicle}</span>
                      </li>
                      <li>
                        <p>Do you have original factory rims?</p>
                        <span class="value">{hasOriginalRims}</span>
                      </li>

                      <li>
                        <p>Have you replaced your tires in last 12 months?</p>
                        <span class="value">{tireReplacement}</span>
                      </li>

                      <li>
                        <p>Is your car driveable?</p>
                        <span class="value">{vehicleDrivable}</span>
                        <span class="value">{vehicledrivabledesc}</span>
                      </li>

                      <li>
                        <p>Any crack on the windshield?</p>
                        <span class="value">{crackOnWindshield}</span>
                      </li>

                      <li>
                        <p>Do you have any extended warranty?</p>
                        <span class="value">{extendedWarranty}</span>
                      </li>

                      <li>
                        <p>Are you interested in a trade In?</p>
                        <span class="value">{tradeInInterest}</span>
                      </li>

                      <li>
                        <p>Why are you selling?</p>
                        <span class="value">{DonotNeedCar}</span>
                        <span class="value">{MechElectIssues}</span>
                        <span class="value">{DownSize}</span>
                        <span class="value">{BuyAnotherCar}</span>
                      </li>

                      <li>
                        <p>Describe the condition of the car</p>
                        <span class="value">{carCondition}</span>
                      </li>

                      <li>
                        <p>Any Accident Claims? How Much?</p>
                        <span class="value">{accidentclaims}</span>
                        <span class="value">{howmuchclaims}</span>
                      </li>

                      <li>
                        <p>What Rims Are On The Car?</p>
                        <span class="value">{carrims}</span>
                      </li>

                      <li>
                        <p>Do You Have Stock Rims?</p>
                        <span class="value">{stockrim}</span>
                      </li>
                      <li>
                        <p>Any Issues With The Vehicle?</p>
                        <span class="value">{issuevichle}</span>
                        <span class="value">{issuevichledecs}</span>
                      </li>
                      <li>
                        <p>Are There Any Modifications On The Car?</p>
                        <span class="value">{carmodify}</span>
                        <span class="value">{carmodifydecs}</span>
                      </li>
                      <li>
                        <p>Is The Car Leased Or Financed?</p>
                        <span class="value">{financed}</span>
                        <span class="value">{financeddesc}</span>
                      </li>
                      <li>
                        <p>Car Notes</p>
                        <span class="value">{vechiclenotes}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="report-section">
                  <div class="car-report d-flex">
                    {imageUrl ? (
                      <img
                        class="mr-3"
                        src={imageUrl}
                        alt="Badge"
                        style={{
                          width: "200px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // Check if evaluation report is available
                          if (
                            !CarfaxReportHideLiensUrl ||
                            CarfaxReportHideLiensUrl === ""
                          ) {
                            alert("Your CarFax report is not available.");
                          } else if (
                            !CarfaxReportHideLiensUrl ||
                            CarfaxReportHideLiensUrl.includes("null")
                          ) {
                            // Check if the dynamic link contains "null"
                            alert("Your CarFax report is not available.");
                          } else {
                            window.open(CarfaxReportHideLiensUrl, "_blank");
                          }
                        }}
                      />
                    ) : (
                      <p>{message}</p>
                    )}
                    <h4 class="info-headline ml-auto">{hasline}</h4>
                  </div>
                </div>

                <div class="alert-panel mt-3">
                  <h4 class="info-headline mb-0">
                    <i class="fa-regular fa-bell "></i> Get Alerts on similar
                    vehicles{" "}
                  </h4>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    onClick={setAlert}
                  >
                    Set Alert
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
