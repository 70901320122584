import React, { useEffect, useState } from "react";
import { SellerNav } from "./DealerNav";
import { useLocation } from "react-router-dom";
import api from "../api"; // Import your API instance
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

export const NagitionBidPopUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bidId, dealerId, sellerId, vehicleId } = location.state || {}; // Destructure the passed state
  const [highestbid, SetHighFetchDataalwsys] = useState('');
  // State to hold counter price and comments
  const [counterPrice, setCounterPrice] = useState("");
  const [comments, setComments] = useState("");
  const numericHighestBid = Number(highestbid);
  const formatCurrency = (value) => {
    if (!value) return '';
    const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const formattedValue = `$${Number(numericValue).toLocaleString()}`; // Format with $ and commas
    return formattedValue;
  };
  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    setCounterPrice(formatCurrency(rawValue)); // Update state with formatted value
  };

  // Log IDs to the console when the component mounts
  useEffect(() => {
    console.log("Negotiation Data:");
    console.log("Bid ID:", bidId);
    console.log("Dealer ID:", dealerId);
    console.log("Seller ID:", sellerId);
    console.log("Vehicle ID:", vehicleId);
  }, [bidId, dealerId, sellerId, vehicleId]);

  // Function to handle form submission
  const handleSubmit = async () => {
    if (!counterPrice) {
      Swal.fire({
        title: "Error",
        text: "Please add a counter price.",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return; // Exit the function
    }
    

    // Assuming bidIds and dealerIds are defined, you may need to adjust them accordingly.
    const bidIds = bidId; // If you have multiple IDs, concatenate them as needed
    const dealerIds = dealerId; // If you have multiple IDs, concatenate them as needed
    const sellerid = sellerId; // Assuming you have the seller ID from state

    const data = {
      BidID: bidIds, // Use the Bid ID from thbe state
      VehicleID: parseInt(vehicleId), // Convert to a number
      DealerID: dealerIds, // Use the Dealer ID from the state
      SellerID: sellerid, // Use the Seller ID from the state
      CounterPrice: parseFloat(counterPrice.replace(/[$,]/g, '')), // Send numeric value only
      Comments: comments, // Comments from the state
      Action: "reply", // Set the action as "new"
      PortalName: "Dealer" // Set the portal name
    };

    try {
      // Parse the numeric value from the counterPrice string
      const numericPrice = parseFloat(counterPrice.replace(/[$,]/g, ''));
      if (numericPrice <= numericHighestBid) {
        Swal.fire({
          title: "Invalid Counter Offer",
          text: `The counter offer must be higher than the highest bid of $${numericHighestBid.toLocaleString("en-US")}.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Exit the function
      }
      // Show confirmation dialog with the dynamic price
   
      const isConfirmed = await Swal.fire({
        title: "New Reserve Price",
        text: `Is $${numericPrice.toLocaleString()} your new reserve price?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if(isConfirmed.isConfirmed) {
        // Proceed with submitting the negotiation if the user confirms
        const response = await api.post('/negotiation', data);
        console.log('API Response:', response.data);
        alert('Negotiation submitted successfully');
        navigate('/nagition-cars');
      } else {
        // User canceled the action
        alert('Negotiation submission canceled.');
      }
    } catch (error) {
      console.error('Error submitting negotiation:', error);
      alert('Error submitting negotiation');
    }
  };
  const fetchalwayshighest = async () => {
    try {
      const response = await api.get(`/dealer/highestbid/${vehicleId}`);
      const data = response.data;
      console.log("testhighestbid",response.data)
      SetHighFetchDataalwsys(data.HighestBid);
    } catch (error) {
     // console.error('Error fetching bid history:', error);
    }
  };

  useEffect(() => {
    fetchalwayshighest();
  }, []); // Empty array ensures this runs only once on component mount
  
   
  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-6">
            <div className="nago-mainwrapper p-4">
              <div className="nagotiate-popup">
                <h5>Negotiate Amount</h5>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={counterPrice}
                    onChange={handlePriceChange} // Update with formatted value
                  />
                </div>
                <h5>Comments</h5>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    placeholder="Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)} // Update state on textarea change
                  ></textarea>
                </div>
                <div className="nego-btn mt-4">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
