import React, { useState, useEffect } from "react";
import { SellerNav } from "./DealerNav";
import { useParams } from "react-router-dom"; // import useParams
export const Unsubscribe = () => {
const { Dealer_Id } = useParams(); // Get the ID from the URL slug


      useEffect(() => {
        
        if (Dealer_Id) {
          // Store Dealer_Id in sessionStorage
          sessionStorage.setItem("user_id", Dealer_Id);
          console.log("Dealer ID stored in session:", Dealer_Id);
        }
      }, [Dealer_Id]); // Runs when Dealer_Id changes
    return (
  <section className="car-details">
      <SellerNav />
    
      <div class="container">
      <div class="info-message text-center">
         <i class="fa-regular fa-circle-xmark mb-3"></i>
         <h2>  Thank you for updating your preferences. You have been successfully unsubscribed from our mailing list.</h2>
      </div>
</div>
    
        </section>

    );
};