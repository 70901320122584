import React, { useState, useEffect } from "react";
import { SellerNav } from "./DealerNav";
import { useParams } from "react-router-dom"; // import useParams
import api from "../api";
export const AlertListing = () => {
const { Dealer_Id } = useParams(); // Get the ID from the URL slug
const [posts, setPosts] = useState([]);
const [loading, setLoading] = useState(true); // Add a loading state
const fetchAllListing = async () => {
  try {
    const response = await api.get(`dealer/alertlisting/${Dealer_Id}`);
  console.log(response.data)
  const { message, Vehicle } = response.data;
  // Check if message is true and Vehicle is an array
  if (message && Array.isArray(Vehicle)) {
    // Log the entire response data
    // Set the Vehicle data in state
    setPosts(Vehicle);
    setLoading(false);
  } else {
    throw new Error("Invalid data format: Vehicle array not found");
  }
  } catch (error) {
    console.error("Error fetching negotiation history:", error);
  }
};

      useEffect(() => {
        
        if (Dealer_Id) {
          // Store Dealer_Id in sessionStorage
          sessionStorage.setItem("user_id", Dealer_Id);
          console.log("Dealer ID stored in session:", Dealer_Id);
        }
        fetchAllListing();
      }, [Dealer_Id]); // Runs when Dealer_Id changes
    return (
  <section className="car-details">
      <SellerNav />
      <div className="container">
      <div class="toplisting">
            <h3 className="main-heading py-3">Alert Listings</h3>
          </div>
      <div className="carbid-gallery-panel py-4">
              <div className="row">
                {loading ? (
                  <p>Loading...</p> // Render loading state while fetching data
                ) : posts.length === 0 ? (
                  <div className="col-md-12">
                    <p className="text-center text-danger">No Car in your Alert Listing</p>
                  </div>
                ) : (
                  posts.map((post) => (
                    <div key={post.id} className="col-lg-4 col-12">
                      <a href={`/alert-listing-cardetails/${post.Vehicle_Id}`}>
                        <div className="carbid-image-panel">
                          {/* Replace 'your_static_id' with your desired static ID */}
                          {post.Exterior_Image && (
                            <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`}
                              alt="Car"
                            />
                          )}
                          {post.Exterior_Image2 && !post.Exterior_Image && (
                            <img
                              src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image2}`}
                              alt="Car"
                            />
                          )}
                          {post.Exterior_Image3 &&
                            !post.Exterior_Image2 &&
                            !post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image3}`}
                                alt="Car"
                              />
                            )}
                          {post.Exterior_Image4 &&
                            !post.Exterior_Image3 &&
                            !post.Exterior_Image2 &&
                            !post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image4}`}
                                alt="Car"
                              />
                            )}
                          <div className="bidpanel-innercont">
                            <h6 className="pt-2 text-capitalize">
                              {post.Year} {post.Make} {post.Model}
                            </h6>
                            <span>
                              <small>Vin #{post.VIN}</small>
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))
                )}
              </div>
            </div>
            </div>
        </section>

    );
};